<template>
  <div class="history">
    <!-- 消费里程/充值里程 -->
    <moindexZero
      class="his-items"
      :idNameLine="datas.one1.idLine"
      :titleName="this.$t('optionsHis.shebeis.type1')"
      :idnamePie="datas.one1.idPie"
      :dayData="this.datas.one1.daysData"
      :allDataCurrentTotal="this.datas.one1.allCurrentTotal"
      :dayDataB="this.datas.one1.daysDataB"
      :allDataBCurrentTotal="this.datas.one1.allBCurrentTotal"
      @sureTime="setData"
      @sureTimeMonth="setMonth"
    ></moindexZero>
    <!-- 活跃车辆/换电车辆 -->
    <electricityScooter
      class="his-items"
      :idNameLine="datas.one10.idLine"
      :titleName="this.$t('optionsHis.shebeis.type10')"
      :idnamePie="datas.one10.idPie"
      :dayData="this.datas.one10.daysData"
      :allData="this.datas.one10.all"
      :activeVehiclesTotal="datas.one10.activeVehiclesTotal"
      :swapVehiclesTotal="datas.one10.swapVehiclesTotal"
      @sureTime="setData"
      @sureTimeMonth="setMonth"
    ></electricityScooter>
    <!-- 换电次数/换电充电柜数 -->
    <changeNumber
      class="his-items"
      :idNameLine="datas.one9.idLine"
      :titleName="this.$t('optionsHis.shebeis.type9')"
      :idnamePie="datas.one9.idPie"
      :dayData="this.datas.one9.daysData"
      :allData="this.datas.one9.all"
      :exchangeNumberTotal="this.datas.one9.currentTotal1"
      :stationTotal="this.datas.one9.currentTotal"
      @sureTime="setData"
      @sureTimeMonth="setMonth"
    ></changeNumber>
    <!-- 订单数量/订单金额 -->
    <order
      class="his-items"
      :idNameLine="datas.one6.idLine"
      :titleName="this.$t('optionsHis.shebeis.type6')"
      :idnamePie="datas.one6.idPie"
      :dayData="this.datas.one6.daysData"
      :allData="this.datas.one6.all"
      :orderAmountTotal="this.datas.one6.currentTotal"
      :orderNumTotal="this.datas.one6.currentTotal1"
      @sureTime="setData"
      @sureTimeMonth="setMonth"
    ></order>
    <!-- 充值里程套餐数量分布 -->
    <rechargePackage
      class="his-items"
      :idNameLine="datas.one11.idLine"
      :titleName="this.$t('optionsHis.shebeis.type11')"
      :idnamePie="datas.one11.idPie"
      :dayData="this.datas.one11"
      :allData="this.datas.one11.km100"
      @sureTime="setData"
      @sureTimeMonth="setMonth"
    ></rechargePackage>
    <!-- 绑车用户/注册用户 -->
    <moindexThree
      class="his-items"
      :idNameLine="datas.one5.idLine"
      :titleName="this.$t('optionsHis.shebeis.type5')"
      :idnamePie="datas.one5.idPie"
      :idnamePie2="datas.one5.idPie2"
      :dayData="this.datas.one5.daysData"
      :allData="this.datas.one5.all"
      :dayDataB="this.datas.one5.addData"
      :allDataB="this.datas.one5.addAliveCount"
      :totalB="datas.one5.totalB"
      :totalA="datas.one5.totalA"
      @sureTime="setData"
      @sureTimeMonth="setMonth"
    ></moindexThree>
    <!-- 电动车 -->
    <moindexScooter
      class="his-items"
      :idNameLine="datas.one2.idLine"
      :titleName="this.$t('optionsHis.shebeis.type2')"
      :idnamePie="datas.one2.idPie"
      :dayData="this.datas.one2.daysData"
      :allData="this.datas.one2.all"
      :total="this.datas.one2.total"
      :activateTotal="this.datas.one2.activateTotal"
      @sureTime="setData"
      @sureTimeMonth="setMonth"
    ></moindexScooter>
    <!-- 运营换电柜 -->
    <moindexStation
      class="his-items"
      :idNameLine="datas.one4.idLine"
      :titleName="this.$t('optionsHis.shebeis.type4')"
      :idnamePie="datas.one4.idPie"
      :dayData="this.datas.one4"
      :allData="this.datas.one4.eightArkAdd"
      :exchangeNumberTotal="this.datas.one4.operationTotal"
      :stationTotal="this.datas.one4.total"
      @sureTime="setData"
      @sureTimeMonth="setMonth"
    ></moindexStation>
    <!-- 电池 -->
    <moindex
      class="his-items"
      :idNameLine="datas.one3.idLine"
      :titleName="this.$t('optionsHis.shebeis.type3')"
      :idnamePie="datas.one3.idPie"
      :dayData="this.datas.one3.daysData"
      :allData="this.datas.one3.all"
      @sureTime="setData"
      @sureTimeMonth="setMonth"
    ></moindex>
    <!-- 用电量 -->
    <moindex
      class="his-items"
      :idNameLine="datas.one8.idLine"
      :titleName="this.$t('optionsHis.shebeis.type8')"
      :idnamePie="datas.one8.idPie"
      :dayData="this.datas.one8.daysData"
      :allData="this.datas.one8.all"
      @sureTime="setData"
      @sureTimeMonth="setMonth"
    ></moindex>
  </div>
</template>
<script>
// import foowwLocalStorage from "../../utils/foow.js";
// import E from "wangeditor";
require("echarts/extension/bmap/bmap");
// const CUSTOM_MAP_CONFIG = require("../../static/custom_map_config.json");
// const format = (time) => {
//   let ymd = "";
//   let mouth =
//     time.getMonth() + 1 >= 10
//       ? time.getMonth() + 1
//       : "0" + (time.getMonth() + 1);
//   let day = time.getDate() >= 10 ? time.getDate() : "0" + time.getDate();
//   ymd += time.getFullYear() + "-"; // 获取年份。
//   ymd += mouth + "-"; // 获取月份。
//   ymd += day; // 获取日。
//   return ymd; // 返回日期。
// };
import moindex from "./common/moindex.vue";
import moindexThree from "./common/moindexThree.vue";
import moindexZero from "./common/moindexZero.vue";
import electricityScooter from "./common/moindexElectricityScooter.vue";
import order from "./common/moindexOrder.vue";
import changeNumber from "./common/moindexexChangeNumber.vue";
import moindexScooter from "./common/moindexScooter.vue";
import moindexStation from "./common/moindexStation.vue";
import rechargePackage from "./common/rechargePackage.vue";
// import GoogleMapsLoader from "google-maps";
import {
  getHistoryWeekScooter,
  getHistoryStation,
  getHistoryBattery,
  getHistoryUser,
  getHistoryMileage,
  getHistoryOrder,
  getHistoryElectricity,
  getHistoryScooter,
  getHistoryScooterTotals,
  getOperationsStation,
  getMileagePackagesNumber,
  getOperationStation,
  getStationTotals
} from "../../request/conlog.js";
export default {
  name: "index",
  components: {
    moindex,
    moindexThree,
    moindexZero,
    electricityScooter,
    order,
    changeNumber,
    moindexScooter,
    moindexStation,
    rechargePackage
  },
  data() {
    return {
      datas: {
        one1: {
          idLine: "one1",
          idPie: "one1p",
          daysData: [],
          all: [],
          daysDataB: [],
          allB: [],
        },
        one2: {
          idLine: "one2",
          idPie: "one2p",
          daysData: [],
          all: [],
        },
        one3: {
          idLine: "one3",
          idPie: "one3p",
          daysData: [],
          all: [],
        },
        one4: {
          idLine: "one4",
          idPie: "one4p",
          daysData: [],
          all: [],
          eightArkAdd: [],
          threeArkAdd: [],
        },
        one5: {
          idLine: "one5",
          idPie: "one5p",
          idPie2: "one5p2",
          daysData: [],
          addData: [],
          addAliveCount: [],
          all: [],
        },
        one6: {
          idLine: "one6",
          idPie: "one6p",
          daysData: [],
          all: [],
        },
        one7: {
          idLine: "one7",
          idPie: "one7p",
          daysData: [],
          all: [],
        },
        one8: {
          idLine: "one8",
          idPie: "one8p",
          daysData: [],
          all: [],
        },
        one9: {
          idLine: "one9",
          idPie: "one9p",
          daysData: [],
          all: [],
        },
        one10: {
          idLine: "one10",
          idPie: "one10p",
          daysData: [],
          all: [],
          activeVehiclesTotal: '',
          swapVehiclesTotal: '',
        },
        one11: {
          idLine: "one11",
          idPie: "one11p",
          km1: [],
          km100: [],
          km250: [],
          km500: [],
          km1000: [],
          km1500: [],
          km2500: [],
          km3500: [],
          other: [],
        },
      },
    };
  },
  computed: {
    startDate() {
      return this.$t("optionsHis.startTime");
    },
    endDate() {
      return this.$t("optionsHis.endTime");
    },
    to() {
      return this.$t("optionsHis.to");
    },
    startMonth() {
      return this.$t("optionsHis.startMonth");
    },
    endMonth() {
      return this.$t("optionsHis.endMonth");
    },
    danselect() {
      return this.$t("optionsHis.select");
    },
    optionsdan() {
      return [
        {
          value: 0,
          label: this.$t("optionsHis.day"),
        },
        {
          value: 1,
          label: this.$t("optionsHis.month"),
        },
      ];
    },
  },
  async created() {
    //生成图表
    this.getData();
  },
  mounted() {
    //前七天
    this.searchFormWidth(); // 组件初始化的时候不会触发onresize事件，这里强制执行一次
    getHistoryScooterTotals().then(res=>{
      let total = res.data.currentTotal[0];
      this.datas.one10.activeVehiclesTotal = String(total.activiteScooter);
      this.datas.one10.swapVehiclesTotal = String(total.changeScooter);
    })
    getStationTotals().then(res=>{
      let total = res.data.currentTotal[0];
      this.datas.one9.currentTotal = String(total.activiteStation);
      this.datas.one9.currentTotal1 = String(total.changeStation);
    })
  },
  methods: {
    getData() {
      this.getRouterA({});
      this.getScooter({});
      this.getBattery({});
      this.getUser({});
      this.getOrderNumber({});
      this.getElectricity({});
      this.getActivity({});
      this.getStation({});
      this.getMileagePackages({})
      this.getOperationStation({})
    },
    // 充值里程套餐数量分布
    getMileagePackages(params){
      let math = require('mathjs'); 
      getMileagePackagesNumber(Object.assign(params)).then(res=>{
        console.log('kn11111',res.data.list)
        let dataList = res.data.list;
        this.datas.one11.km100=[]
        this.datas.one11.km250=[]
        this.datas.one11.km500=[]
        this.datas.one11.km1000=[]
        this.datas.one11.km1500=[]
        this.datas.one11.km2500=[]
        this.datas.one11.km3500=[]
        this.datas.one11.other=[]
        this.datas.one11.km1=[]
        for(let i = dataList.length - 1; i > -1; i--) {
          // this.datas.one11.km1.push(dataList[i].km1);
          // this.datas.one11.km100.push(dataList[i].km100);
          // this.datas.one11.km250.push(dataList[i].km250);
          // this.datas.one11.km500.push(dataList[i].km500);
          // this.datas.one11.km1000.push(dataList[i].km1000);
          // this.datas.one11.km1500.push(dataList[i].km1500);
          // this.datas.one11.km2500.push(dataList[i].km2500);
          // this.datas.one11.km3500.push(dataList[i].km3500);
          // this.datas.one11.other.push(dataList[i].other);
          this.datas.one11.km1.push(math.random() * [i]);
          this.datas.one11.km100.push(math.random() * 8);
          this.datas.one11.km250.push(math.random() * 6);
          this.datas.one11.km500.push(math.random() * 3);
          this.datas.one11.km1000.push(math.random() * 8);
          this.datas.one11.km1500.push(math.random() * 5);
          this.datas.one11.km2500.push(math.random() * 9);
          this.datas.one11.km3500.push(math.random() * 1);
          this.datas.one11.other.push(math.random() * 4);
        }
        console.log(this.datas.one11)
      })
    },
    //在运营换电柜
    getOperationStation(params){
      getOperationStation(Object.assign(params)).then(res=>{
        let dataList = res.data.list;
        this.datas.one4.eightArkAdd = []
        this.datas.one4.threeArkAdd = []
        this.datas.one4.eightArkTotal = []
        this.datas.one4.threeArkTotal = []
        for(let i = dataList.length - 1; i > -1; i--) {
          this.datas.one4.eightArkAdd.push(dataList[i].eightArkAdd);
          this.datas.one4.threeArkAdd.push(dataList[i].threeArkAdd);
          this.datas.one4.eightArkTotal.push(dataList[i].eightArkTotal);
          this.datas.one4.threeArkTotal.push(dataList[i].threeArkTotal);
        }
      })
    },
    //活跃车辆/换电车辆
    getActivity(params) {
      getHistoryScooter(Object.assign(params)).then((res)=>{
        if(res.errcode === 200){
          let dataList = res.data.list;
          this.datas.one10.daysData = [];
          this.datas.one10.all = [];
          for (let i = dataList.length - 1; i > -1; i--) {
            this.datas.one10.daysData.push(dataList[i].activiteScooter);
            this.datas.one10.all.push(dataList[i].changeScooter);
          }
        }
      })
    },
    //消费里程/充值里程
    getRouterA(params, paramsB) {
      getHistoryMileage(Object.assign(params)).then((res) => {
        if (res.errcode === 200) {
          let dataList = res.data.list;
          let total = res.data.currentTotal[0]
          this.datas.one1.daysData = [];
          this.datas.one1.daysDataB = [];
          this.datas.one1.allCurrentTotal = total.consumeValue;
          this.datas.one1.allBCurrentTotal = total.rechargeValue;
          for (let i = dataList.length - 1; i > -1; i--) {
            this.datas.one1.daysData.push(dataList[i].consumeValue);
            this.datas.one1.daysDataB.push(dataList[i].rechargeValue);
          }
        }
      });
    },
    //电动车
    getScooter(params) {
      let math =  require('mathjs')
      getHistoryWeekScooter(Object.assign(params)).then((res) => {
        if (res.errcode === 200) {
          console.log(8787878,res.data.list)
          const data = res.data.list;
          this.datas.one2.daysData = [];
          this.datas.one2.all = [];
          this.datas.one2.total = res.data.currentTotal[0].total;
          this.datas.one2.activateTotal = res.data.currentTotal[0].activateTotal;
          for (let i = data.length - 1; i > -1; i--) {
            this.datas.one2.daysData.push(math.random() * 8);
          }
        }
      });
    },
    //电池
    getBattery(params) {
      getHistoryBattery(Object.assign(params)).then((res) => {
        if (res.errcode === 200) {
          const data = res.data.list;
          this.datas.one3.daysData = [];
          this.datas.one3.all = [];
          for (let i = data.length - 1; i > -1; i--) {
            this.datas.one3.daysData.push(data[i].data);
            this.datas.one3.all.push(data[i].currtotal);
          }
        }
      });
    },
    //换电次数/换电充电柜数
    getStation(params) {
      getHistoryStation(Object.assign(params)).then((res) => {
        if (res.errcode === 200) {
          const data = res.data.list;
          this.datas.one9.all = [];
          this.datas.one9.daysData = [];
          for (let i = data.length - 1; i > -1; i--) {
            this.datas.one9.all.push(data[i].activiteStation);
            this.datas.one9.daysData.push(data[i].changeStation);
          }
        }
      });
    },
    //运营换电柜
    getOperationsStation(params) {
      getOperationsStation(Object.assign(params)).then((res) => {
        if (res.errcode === 200) {
          const data = res.data.list;
          this.datas.one4.daysData = [];
          this.datas.one4.all = [];
          for (let i = data.length - 1; i > -1; i--) {
            this.datas.one4.daysData.push(data[i].data);
            this.datas.one4.all.push(data[i].currtotal);
          }
          this.datas.one4.operationTotal = res.data.currentTotal[0].operationTotal
          this.datas.one4.total = res.data.currentTotal[0].total
        }
      });
    },
    //用户
    getUser(params) {
      getHistoryUser(Object.assign(params)).then((res) => {
        if (res.errcode === 200) {
          console.log(8888888888,res.data.list)
          let math = require('mathjs'); 
          const data = res.data.list;
          this.datas.one5.daysData = [];
          this.datas.one5.all = [];
          this.datas.one5.addData = [];
          this.datas.one5.addAliveCount = [];
          for (let i = data.length - 1; i > -1; i--) {
            data[i].addRegister = math.random() * 1205400
            data[i].addBind = math.random() * 12054005
            this.datas.one5.daysData.push(data[i].addRegister);
            this.datas.one5.addAliveCount.push(data[i].addBind);
          }
          this.datas.one5.totalA = res.data.currentTotal.aliveCount
          this.datas.one5.totalB = res.data.currentTotal.totalCount
        }
      });
    },
    // 订单数量/订单金额
    getOrderNumber(params) {
      getHistoryOrder(Object.assign(params)).then((res) => {
        if (res.errcode === 200) {
          const data = res.data.list;
          const total = res.data.currentTotal[0];
          this.datas.one6.currentTotal = total.orderNum;
          this.datas.one6.currentTotal1 = total.orderAmount;
          this.datas.one6.all = [];
          this.datas.one6.daysData = [];
          for (let i = data.length - 1; i > -1; i--) {
            this.datas.one6.all.push(data[i].orderNum);
            this.datas.one6.daysData.push(data[i].orderAmount);
          }
        }
      });
    },
    //用电量
    getElectricity(params) {
      getHistoryElectricity(Object.assign(params)).then((res) => {
        if (res.errcode === 200) {
          const data = res.data.list;
          this.datas.one8.daysData = [];
          this.datas.one8.all = [];
          for (let i = data.length - 1; i > -1; i--) {
            this.datas.one8.daysData.push(data[i].data);
            this.datas.one8.all.push(data[i].currtotal);
          }
        }
      });
    },
    setData(obj) {
      if (obj.id == this.datas.one1.idLine) {
        this.getRouterA({
          type: "0",
          startTime: obj.time[0] + " 00:00:00",
          endTime: obj.time[obj.time.length - 1] + " 23:59:59",
        },
        );
      } else if (obj.id == this.datas.one2.idLine) {
        this.getScooter({
          type: "0",
          startTime: obj.time[0] + " 00:00:00",
          endTime: obj.time[obj.time.length - 1] + " 23:59:59",
        });
      } else if (obj.id == this.datas.one3.idLine) {
        this.getBattery({
          type: "0",
          startTime: obj.time[0] + " 00:00:00",
          endTime: obj.time[obj.time.length - 1] + " 23:59:59",
        });
      } else if (obj.id == this.datas.one4.idLine) {
        this.getOperationStation({
          type: "0",
          startTime: obj.time[0] + " 00:00:00",
          endTime: obj.time[obj.time.length - 1] + " 23:59:59",
        });
      } else if (obj.id == this.datas.one5.idLine) {
        this.getUser({
          type: "0",
          startTime: obj.time[0] + " 00:00:00",
          endTime: obj.time[obj.time.length - 1] + " 23:59:59",
        });
      } else if (obj.id == this.datas.one6.idLine) {
        this.getOrderNumber({
          type: "0",
          startTime: obj.time[0] + " 00:00:00",
          endTime: obj.time[obj.time.length - 1] + " 23:59:59",
        });
      } else if (obj.id == this.datas.one7.idLine) {
        this.getOrderMoney({
          type: "0",
          startTime: obj.time[0] + " 00:00:00",
          endTime: obj.time[obj.time.length - 1] + " 23:59:59",
        });
      } else if (obj.id == this.datas.one8.idLine) {
        this.getElectricity({
          type: "0",
          startTime: obj.time[0] + " 00:00:00",
          endTime: obj.time[obj.time.length - 1] + " 23:59:59",
        });
      } else if (obj.id == this.datas.one9.idLine) {
        this.getStation({
          type: "0",
          startTime: obj.time[0] + " 00:00:00",
          endTime: obj.time[obj.time.length - 1] + " 23:59:59",
        });
      } else if (obj.id == this.datas.one10.idLine) {
        this.getActivity({
          type: "0",
          startTime: obj.time[0] + " 00:00:00",
          endTime: obj.time[obj.time.length - 1] + " 23:59:59",
        });
      }else if (obj.id == this.datas.one11.idLine) {
        this.getMileagePackages({
          type: "0",
          startTime: obj.time[0] + " 00:00:00",
          endTime: obj.time[obj.time.length - 1] + " 23:59:59",
        });
      }
    },
    getTimeDay(value) {
      let time = value.split("-");
      var lastDay = new Date(time[0], time[1], 0);
      var year = lastDay.getFullYear();
      var month = lastDay.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      var day = lastDay.getDate();
      day = day < 10 ? "0" + day : day;
      return value + "-" + day;
    },
    setMonth(obj) {
      if (obj.id == this.datas.one1.idLine) {
        const start = obj.time[0] + "-01 00:00:00";
        const end = this.getTimeDay(obj.time[obj.time.length - 1]) + " 23:59:59";
        this.getRouterA({ type: "1", startTime: start, endTime: end });
      } else if (obj.id == this.datas.one2.idLine) {
        const start = obj.time[0] + "-01 00:00:00";
        const end = this.getTimeDay(obj.time[obj.time.length - 1]) + " 23:59:59";
        this.getScooter({ type: "1", startTime: start, endTime: end });
      } else if (obj.id == this.datas.one3.idLine) {
        const start = obj.time[0] + "-01 00:00:00";
        const end = this.getTimeDay(obj.time[obj.time.length - 1]) + " 23:59:59";
        this.getBattery({ type: "1", startTime: start, endTime: end });
      } else if (obj.id == this.datas.one4.idLine) {
        const start = obj.time[0] + "-01 00:00:00";
        const end = this.getTimeDay(obj.time[obj.time.length - 1]) + " 23:59:59";
        this.getOperationStation({ type: "1", startTime: start, endTime: end });
      } else if (obj.id == this.datas.one5.idLine) {
        const start = obj.time[0] + "-01 00:00:00";
        const end = this.getTimeDay(obj.time[obj.time.length - 1]) + " 23:59:59";
        this.getUser({ type: "1", startTime: start, endTime: end });
      } else if (obj.id == this.datas.one6.idLine) {
        const start = obj.time[0] + "-01 00:00:00";
        const end = this.getTimeDay(obj.time[obj.time.length - 1]) + " 23:59:59";
        this.getOrderNumber({ type: "1", startTime: start, endTime: end });
      } else if (obj.id == this.datas.one7.idLine) {
        const start = obj.time[0] + "-01 00:00:00";
        const end = this.getTimeDay(obj.time[obj.time.length - 1]) + " 23:59:59";
        this.getOrderMoney({ type: "1", startTime: start, endTime: end });
      } else if (obj.id == this.datas.one8.idLine) {
        const start = obj.time[0] + "-01 00:00:00";
        const end = this.getTimeDay(obj.time[obj.time.length - 1]) + " 23:59:59";
        this.getElectricity({ type: "1", startTime: start, endTime: end });
      } else if (obj.id == this.datas.one9.idLine) {
        const start = obj.time[0] + "-01 00:00:00";
        const end = this.getTimeDay(obj.time[obj.time.length - 1]) + " 23:59:59";
        this.getStation({ type: "1", startTime: start, endTime: end });
      } else if (obj.id == this.datas.one10.idLine) {
        const start = obj.time[0] + "-01 00:00:00";
        const end = this.getTimeDay(obj.time[obj.time.length - 1]) + " 23:59:59";
        this.getActivity({ type: "1", startTime: start, endTime: end });
      } else if (obj.id == this.datas.one11.idLine) {
        const start = obj.time[0] + "-01 00:00:00";
        const end = this.getTimeDay(obj.time[obj.time.length - 1]) + " 23:59:59";
        this.getMileagePackages({ type: "1", startTime: start, endTime: end });
      }
    },
    searchFormWidth() {
      let w = window.innerWidth;
      if (w <= 767) {
        this.tdWidth = "120";
      } else {
        this.tdWidth = "440";
      }
    },
  },
};
</script>

<style>
.history {
  widows: calc(100%-60px);
  padding: 0 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: #000B3F;
}
.title {
  font-size: 26px;
  margin: 10px;
  font-weight: 700;
  background-image: -webkit-linear-gradient(bottom, #5197ff, #80f2ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content1 {
  display: flex;
  color: #fff;
  justify-content: space-between;
  margin: auto 1rem;
}
.his-items {
  width: 49%;
}
.content2 {
  display: flex;
  margin-top: 2rem;
}
.content2-right {
  width: 22rem;
  height: 17rem;
  border: 0.15rem solid #0089ff;
  padding: 1.2rem;
}
.content3-title {
  text-align: center;
  color: #fff;
  background-color: #0089ff;
  width: 9rem;
  height: 2rem;
  margin: auto;
  line-height: 2rem;
  margin-top: -1rem;
}
.content3-items {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}
.content3-item {
  color: #fff;
  height: 2rem;
  text-align: center;
  margin: 0 1rem;
}
.content3-item-name {
  color: #9feaf8;
}
.content3-item-number {
  margin-top: 1rem;
}
.content3-item-number span {
  color: #0191a9;
  padding: 0.5rem 0.3rem;
  background-color: #092126;
  margin: 0 0.05rem;
}
.content4-item {
  width: 25rem;
  height: 25rem;
}
</style>
<style >
.el-range-editor.el-input__inner {
  background-color: #1E3963;
}
.el-input__inner {
  border: 1px solid #1E3963;
  height: 27px;
  line-height: 27px;
  border-radius: 0;
}
.el-date-editor .el-range-separator {
  color: #66beff;
}

.el-range-editor .el-range-input {
  background-color: #1E3963;
  color: #026476;
}
.danselect {
  margin-right: 1rem;
}
.el-input--suffix .el-input__inner {
  background-color: #1E3963;
}
.el-select .el-input.is-focus .el-input__inner {
}
.el-select .el-input {
  width: 80px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.active {
  display: none;
}
#contentDitor img {
  max-width: 450px;
}
.el-input__icon{
  line-height: 27px;
}
.el-date-editor .el-range__icon {
  line-height: 20px;
}
.el-date-editor .el-range-separator {
  line-height: 20px;
}
.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{
  width: 240px;
}
.el-date-editor .el-range__close-icon {
    line-height: 20px;
}
@media screen and (max-width: 767px) {
  #contentDitor img {
    max-width: 226px;
  }
  .el-form-item__label {
    text-align: none;
  }
  .el-pager {
    display: none;
  }
  .el-pagination .el-select .el-input {
    margin: 0;
  }
}
</style>
