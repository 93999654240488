<template>
  <div>
    <div class="history-one">
      <div class="his-content1-top">
        <div class="his-content1-top-name">{{ titleName }}</div>
        <div class="his-content1-top-number">
          <div class="his-content1-top-number-new" style="display: none">
            {{ $t("optionsHis.add") }}：{{ hisoptions.number }}
          </div>
          <el-select v-model="value" @change="selectChanged" :placeholder="danselect" class="danselect">
            <el-option v-for="item in optionsdan" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-date-picker v-show="value == 1" v-model="value1" value-format="yyyy-MM-dd" type="monthrange"
            @change="setDateOneMonth()" :range-separator="to" :start-placeholder="startMonth" :end-placeholder="endMonth">
          </el-date-picker>
          <el-date-picker v-show="value == 0" v-model="value1" type="daterange" align="right" value-format="yyyy-MM-dd"
            unlink-panels @change="setDateOne()" :range-separator="to" :start-placeholder="startDate"
            :end-placeholder="endDate" :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </div>
      <div style="display: flex;justify-content: space-between;padding-right: 30px;">
        <div class="content-title" style="display: flex;flex-direction: row;">
          <div class="itemsd" style="color: #00859a; margin-bottom: 0.02rem">
            <p style="background-color: #00859a"></p>
            {{ $t("registeredUsersA") }}
          </div>
          <div class="itemsd" style="color: #0062a9; margin-bottom: 0.02rem">
            <p style="background-color: #0062a9"></p>
            {{ $t("activeUsersA") }}
          </div>
        </div>
        <div class="content-title" style="display: flex;align-items: flex-end;">
          <div class="itemsd" style="color: #FFFFFF; margin-bottom: 0.02rem">
            {{ $t("registeredUsersB") }}：{{ Thousands(totalB) }}
          </div>
          <div class="itemsd" style="color: #FFFFFF">
            {{ $t("activeUsersB") }}：{{ Thousands(totalA) }}
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: space-around;padding-bottom: 15px;">
        <div :id="idNameLine" class="one-add1" style="width: 100%;"></div>
        <div class="one-add2" style="width: 40%; display: none" :id="idnamePie"></div>
      </div>
      <div class="boxfoot"></div>
    </div>
  </div>
</template>
<script>
const format = (time) => {
  let ymd = "";
  let mouth =
    time.getMonth() + 1 >= 10
      ? time.getMonth() + 1
      : "0" + (time.getMonth() + 1);
  let day = time.getDate() >= 10 ? time.getDate() : "0" + time.getDate();
  ymd += time.getFullYear() + "-"; // 获取年份。
  ymd += mouth + "-"; // 获取月份。
  ymd += day; // 获取日。
  return ymd; // 返回日期。
};
export default {
  name: "",
  props: {
    titleName: {
      type: String,
      default: "",
    },
    idNameLine: {
      type: String,
      default: "",
    },
    idnamePie: {
      type: String,
      default: "",
    },
    allData: {
      type: Array,
      default() {
        return [];
      },
    },
    dayData: {
      type: Array,
      default() {
        return [];
      },
    },
    allDataB: {
      type: Array,
      default() {
        return [];
      },
    },
    dayDataB: Array,
    totalA: String,
    totalB: String,
  },
  computed: {
    startDate() {
      const tabletime = this.getWeekDate();
      return tabletime[0];
    },
    endDate() {
      const tabletime = this.getWeekDate();
      return tabletime[1];
    },
    to() {
      return this.$t("optionsHis.to");
    },
    startMonth() {
      let dd = this.getLastSixMon();
      return dd[5];
    },
    endMonth() {
      let dd = this.getLastSixMon();
      return dd[0];
    },
    danselect() {
      return this.$t("optionsHis.select");
    },
    optionsdan() {
      return [
        {
          value: 0,
          label: this.$t("optionsHis.day"),
        },
        {
          value: 1,
          label: this.$t("optionsHis.month"),
        },
      ];
    },
  },
  data() {
    return {
      day: "New:",
      name: "",
      common: {
        time: [],
      },
      Year: "",
      value: 0,
      //图表
      hisoptions: {
        elecar: null, //电动车
        elecar2: null,
        elecardata: [],
        elecardata2: [],
        number: 0,
      },
      //数据
      one: {
        text: "电动车",
        datatime: [],
        datas: [],
        datas2: [],
        bdatas: [],
      },
      value3: "",
      value2: "",
      value1: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last ten days",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 10);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Last half month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.setTime();
    this.setAddOneData();
  },
  watch: {
    allData(val, oldVal) {
      this.one.datas = this.dayData; //调用接口 获取数据
      this.one.datas2 = val;
      this.setAllData();
    },
    "$i18n.locale"(newValue) {
      this.setAddOneData();
    },
  },
  methods: {
    //处理数据
    setAllData() {
      this.one.bdatas = [];
      this.hisoptions.number = 0;
      for (let i = 0; i < this.one.datatime.length; i++) {
        const oneObject = {
          value: this.one.datas[i],
          name: this.one.datatime[i],
        };
        this.hisoptions.number += this.one.datas[i];
      }
      this.one.bdatas.push(
        {
          value: this.one.datas[0],
          name: "当天",
          itemStyle: { color: "#5470c6" },
        },
        {
          value: this.one.datas2[0],
          name: "总数",
          itemStyle: { color: "#91cc75" },
        }
      );
      this.setAddOneData();
    },
    selectChanged(val) {
      if (val == 1) {
        this.day = "Month:";
        let dd = this.getLastSixMon();
        this.value1 = [dd[5], dd[0]];
        const time = this.getMonthBetween(dd[5], dd[0]); //八天的时间
        this.one.datatime = time;
        //调用接口
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        month = month < 10 ? "0" + month : month;
        var mydate = year.toString() + "-" + month.toString();
        const code = {
          id: this.idNameLine,
          time: time,
        };
        this.$emit("sureTimeMonth", code);
      } else {
        this.day = "Day:";
        const sd = this.getWeekDate();
        this.value1 = [sd[0], sd[1]];
        const time = this.getAllDate(sd[0], sd[1]); //八天的时间
        //调用接口
        this.one.datatime = time;
        const code = {
          id: this.idNameLine,
          time: time,
        };
        var myDate = new Date();
        const d1 = time[time.length - 1].replace(/-/g, "/");
        const d = myDate.toLocaleDateString(); //当前日期
        this.$emit("sureTime", code);
      }
    },
    //前一周的时间
    setTime() {
      const tabletime = this.getWeekDate();
      this.common.time = this.getAllDate(tabletime[0], tabletime[1]);
      this.one.datatime = this.common.time;
      this.one.datas = this.dayData;
      this.one.datas2 = this.allData;
      for (let i = 0; i < this.one.datatime.length; i++) {
        const oneObject = {
          value: this.one.datas[i],
          name: this.one.datatime[i],
        };
        this.hisoptions.number += this.one.datas[i];
      }
      this.one.bdatas.push(
        {
          value: this.one.datas[0],
          name: "当天",
          itemStyle: { color: "#00859A" },
        },
        {
          value: this.one.datas2[0],
          name: "总数",
          itemStyle: { color: "#0062A9" },
        }
      );
    },
    //日期选择  电动车
    setDateOne(val) {
      const time = this.getAllDate(this.value1[0], this.value1[1]); //八天的时间
      //调用接口
      this.one.datatime = time;
      const code = {
        id: this.idNameLine,
        time: time,
      };
      var myDate = new Date();
      const d1 = time[time.length - 1].replace(/-/g, "/");
      const d = myDate.toLocaleDateString(); //当前日期
      this.$emit("sureTime", code);
    },
    setDateOneMonth(val) {
      const time = this.getMonthBetween(this.value1[0], this.value1[1]); //八天的时间
      this.one.datatime = time;
      //调用接口
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      var mydate = year.toString() + "-" + month.toString();
      const code = {
        id: this.idNameLine,
        time: time,
      };
      this.$emit("sureTimeMonth", code);
    },
    //千分数字处理
    Thousands(num) {
      num = num + '';
      if (!num.includes('.')) {
        num += '.'
      }
      return num.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
        return $1 + ',';
      }).replace(/\.$/, '');

    },
    //电动车图表
    setAddOneData() {
      let that = this;
      this.hisoptions.elecar = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          backgroundColor: "#fff", //背景色
          padding: [5, 15, 5, 15], //边距6
          borderColor: "#DDDDDF", //边框颜色
          borderWidth: 1, //边框线宽度
          textStyle: {
            //文字样式
            color: "#6A6A6A",
            decoration: "none",
            fontFamily: "Verdana, sans-serif",
          },
          extraCssText: "text-align: left;", //文字对齐方式
          formatter: function (params) {
            //格式化函数
            return (
              params[0].name +
              "</br>" +
              that.$t(`registeredUsersA`) +
              ": " +
              that.Thousands(params[0].data) +
              "</br>" +
              that.$t(`activeUsersA`) +
              ": " +
              that.Thousands(params[1].data)
            );
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.one.datatime,
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#6173A3",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            position: "left",
            splitLine: {
              lineStyle: {
                color: "rgba(160,160,160,0.3)",
              },
            },
            axisLine: {
              show: true, //x轴是否显示
              lineStyle: {
                color: "#6173A3",
              },
            },
          },
        ],
        series: [
          {
            data: this.one.datas,
            type: "bar",
            barWidth: "20%",
            itemStyle: {
              color: "#00859A",
            },
          },
          {
            data: this.allDataB,
            type: "bar",
            barWidth: "20%",
            itemStyle: {
              color: "#0062A9",
            },
          },
        ],
      };
      const eleCkick = this.$echarts.init(
        document.getElementById(this.idNameLine)
      );
      eleCkick.setOption(this.hisoptions.elecar);
      this.testone(this.one.datatime[0]);
      //点击事件
      eleCkick.on("click", function (params) {
        that.eleCkick(params);
      });
    },
    testone(name) {
      const key = "optionsHis.common.";
      const legendData = [`${key}sameday`, `${key}total`];
      this.one.bdatas[0].name = `${key}sameday`;
      this.one.bdatas[1].name = `${key}total`;
      const v0 = this.one.bdatas[1].value;
      const v1 = this.one.bdatas[0].value;
      const v = v0 - v1;
      let match = Math.round((v1 / v0) * 100);
      if (v1 === 0 && v0 === 0) {
        match = 0;
      }
      this.hisoptions.elecar2 = {
        title: [
          {
            text: "占比" + match + "%",
            x: "center",
            y: "center",
            textStyle: {
              color: "#00859A",
              fontSize: 14,
            },
          },
        ],
        series: [
          {
            type: "pie",
            radius: ["50%", "65%"],
            color: "#00859A",
            label: {
              normal: {
                position: "center",
              },
            },
            data: [
              {
                value: v1,
              },
              {
                value: v,
                itemStyle: {
                  normal: {
                    color: "rgba(255,255,255,.2)",
                  },
                  emphasis: {
                    color: "#fff",
                  },
                },
              },
            ],
          },
        ],
      };
      this.$echarts
        .init(document.getElementById(this.idnamePie))
        .setOption(this.hisoptions.elecar2);
    },
    eleCkick(params) {
      const key = "options.common.";
      const legendData = [`${key}type1`, `${key}type2`];
      this.one.bdatas = [
        { value: this.one.datas[params.dataIndex], name: `${key}type1` },
        { value: this.one.datas2[params.dataIndex], name: `${key}type2` },
      ];
      this.testone(params.name);
    },
    getLastSixMon() {
      var data = new Date();
      //获取年
      var year = data.getFullYear();
      //获取月
      var mon = data.getMonth() + 1;
      var arry = new Array();
      for (var i = 0; i < 6; i++) {
        if (mon <= 0) {
          year = year - 1;
          mon = mon + 12;
        }
        if (mon < 10) {
          mon = "0" + mon;
        }
        arry[i] = year + "-" + mon;
        mon = mon - 1;
      }
      return arry;
    },
    getWeekDate() {
      var Date8 = new Date();
      var Date7 = new Date(Date8.getTime() - 24 * 60 * 60 * 1000);
      var Date1 = new Date(Date8.getTime() - 168 * 60 * 60 * 1000);
      let lastWeek = [];
      lastWeek.push(format(Date1));
      lastWeek.push(format(Date7));
      return lastWeek;
    },
    //日期
    getAllDate(start, end) {
      let dateArr = [];
      let startArr = start.split("-");
      let endArr = end.split("-");
      let db = new Date();
      db.setUTCFullYear(startArr[0], startArr[1] - 1, startArr[2]);
      let de = new Date();
      de.setUTCFullYear(endArr[0], endArr[1] - 1, endArr[2]);
      let unixDb = db.getTime();
      let unixDe = de.getTime();
      let stamp;
      const oneDay = 24 * 60 * 60 * 1000;
      for (stamp = unixDb; stamp <= unixDe;) {
        dateArr.push(format(new Date(parseInt(stamp))));
        stamp = stamp + oneDay;
      }
      return dateArr;
    },
    getMonthBetween(start, end) {
      //传入的格式YYYY-MM
      var result = [];
      var s = start.split("-");
      var e = end.split("-");
      var min = new Date();
      var max = new Date();
      min.setFullYear(s[0], s[1] * 1 - 1, 1); //开始日期
      max.setFullYear(e[0], e[1] * 1 - 1, 1); //结束日期
      var curr = min;
      while (curr <= max) {
        var month = curr.getMonth();
        var monthText = (month + 1) < 10 ? "0" + (month + 1) : (month + 1)
        var str = curr.getFullYear() + "-" + monthText;
        result.push(str);
        curr.setMonth(month + 1);
      }
      return result;
    },
    tixi(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
  },
};
</script>

<style scoped>
.history-one {
  background: rgba(16, 52, 106, 0.7);
  background-size: 100% auto;
  position: relative;
  margin-top: 0.1rem;
}
.div-title {
  border-radius: 18px;
  position: absolute;
  height: 35px;
  width: 60%;
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  left: 20%;
  line-height: 35px;
  text-align: center;
}
.history-one:before,
.history-one:after {
  display: none;
  position: absolute;
  width: 0.06rem;
  height: 0.06rem;
  content: "";
  border-top: 2px solid #02a6b5;
  top: 0;
}
.history-one:before,
.boxfoot:before {
  border-left: 2px solid #02a6b5;
  left: 0;
}
.history-one:after,
.boxfoot:after {
  border-right: 2px solid #02a6b5;
  right: 0;
}
.boxfoot {
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.boxfoot:before,
.boxfoot:after {
  position: absolute;
  width: 0.06rem;
  height: 0.06rem;
  content: "";
  border-bottom: 2px solid #02a6b5;
  bottom: 0;
}
.history-one:before,
.history-one:after {
  position: absolute;
  width: 0.06rem;
  height: 0.06rem;
  content: "";
}
.history-one:before {
  border-left: 2px solid #02a6b5;
  left: 0;
  border-top: 2px solid #02a6b5;
}
.history-one:after {
  border-right: 2px solid #02a6b5;
  right: 0;
  bottom: 0;
}
.danselect {
  margin-right: 0.1rem;
}
.content-title {
  display: flex;
  flex-direction: column;
  justify-items: center;
  font-size: 0.055rem;
}
.itemsd {
  display: flex;
}
.itemsd p {
  width: 0.07rem;
  height: 0.07rem;
  background-color: red;
  border-radius: 0.01rem;
  margin-left: 0.08rem;
  margin-right: 0.02rem;
}
.his-content1-top {
  display: flex;
  justify-content: space-between;
  color: #66beff;
  padding: 0.08rem;
  font-size: 0.08rem;
}
.one-add1 {
  width: 6rem;
  height: 1.5rem;
}
.one-add2 {
  width: 3rem;
  height: 2rem;
}
.his-content1-top-name {}
.his-content1-top-number-new {
  margin-right: 1rem;
}
.his-content1-top-number {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
